<template>
    <div class="agendaroom-comment">
        <CompTable ref="comp_table" title="意见收集" :columns="columns" :tableApi="tableApi" :processData="onProcessData" :data="{ flowStatus: type === 'pending' ? 0 : 1 }">
            <template #operate>
                <RadioGroup v-model="type" type="button" button-style="solid">
                    <Radio label="pending">待处理 ( {{ maxCount }} )</Radio>
                    <Radio label="processed">已处理</Radio>
                </RadioGroup>
            </template>
        </CompTable>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table"

import Route from "../communitymobilization/entity/Route"

export default {
    components: { CompTable },

    data() {
        return {
            tableApi: null,
            type: "pending",
            maxCount: 0,
            columns: [
                {
                    title: "议事主题",
                    key: "title",
                },
                {
                    title: "提出时间",
                    render: (h, params) => this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd"),
                },
                {
                    float: "right",
                    title: "操作",
                    width: 200,
                    maxWidth: 200,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/agendaroomdetail", { id: params.row.id })
                                        },
                                    },
                                },
                                this.type === "pending" ? "前去处理" : "查看"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "删除确定",
                                                content: "您正在删除数据，是否继续？",
                                                onOk: () => {
                                                    this.$get("/gateway/syinfopublish/api/pc/chamber/deleteChamberAdvice", { adviceId: params.row.id }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            // 刷新表格数据
                                                            this.$refs.comp_table.onRefresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
        }
    },

    watch: {
        type(v) {
            this.$refs.comp_table.search({
                flowStatus: v === "pending" ? 0 : 1,
            })
        },
    },

    async created() {
        this.tableApi = `/gateway/syinfopublish/api/pc/chamber/listChamberAdvice?orgCode=${parent.vue.loginInfo.userinfo.communityCode}&orgCodeSelf=${parent.vue.loginInfo.userinfo.orgCode}&flowStatus=0`
    },

    methods: {
        onProcessData(res) {
            if (this.type === "pending") {
                this.maxCount = res.maxCount
            }
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}
</style>
